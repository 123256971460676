import {Paper} from '@mantine/core';

let InfoBlock = () => {
    return (
        <div className='info-block'>
            <h3 style={{marginBottom: "8px"}}>
                Who am I?
            </h3>
            <p style={{textIndent: "25px"}}>
                NYC-based Fullstack Software Engineer with 9+ years in Finance Technology. I bring extensive domain
                expertise to the financial advisory industry, delivering software solutions that are both functional and
                resilient.
            </p>
            <p style={{textIndent: "25px", marginTop: "12px"}}>
                For over {new Date().getFullYear() - 2009} years, I’ve been a passionate coding hobbyist, diving into
                projects from game development to public APIs and open-source software. Each project sharpens my skills
                and fuels my excitement for tech's evolving possibilities.
                As a hobbyist game developer, I blend technical skills with creativity, building interactive experiences
                that push my design and problem-solving abilities. This work continually enhances my professional skill
                set.
            </p>
            <p style={{textIndent: "25px", marginTop: "12px"}}>
                Currently, I work at the Federal Reserve as a fullstack engineer, tackling both frontend and backend
                projects to support secure and efficient financial operations. This role combines complex technical
                challenges with a deep understanding of large-scale financial infrastructure.
            </p>
        </div>

    )
}

export default InfoBlock
