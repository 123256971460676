import React from 'react';
import './projects.css'
import SlideIn from "./SlideIn";

const projectsData = [
    {
        title: 'Fire Emblem Calculator',
        description: 'Used as an exercise in data scraping and form creation: A React front-end housing a form that calculates expected parameters for a character in a video-game, based on the user\'s inputs.',
        icons: [<i className="fab fa-node-js"/>, <i className="fab fa-react"/>],
        image: "/adsasd.png",
        link: 'https://alachhman.github.io/engagegrowthcalc/',
        hasLink: true
    },
    {
        title: 'Untitled RPG',
        description: 'The combination of my two life-long passions: Video games and software engineering. A work-in-progress action game, built in Unity Engine using C# - a true passion project.',
        icons: [<i className="fab fa-unity"/>],
        image: "/exampleimage.png",
        link: 'https://project2.example.com',
        hasLink: false
    },
    // {
    //     title: 'How Much Halal Can I Afford?',
    //     description: 'The combination of my two life-long passions: Video games and software engineering. A work-in-progress action game, built in Unity Engine using C# - a true passion project.',
    //     icons: [<i className="fab fa-unity"/>],
    //     image: "/exampleimage.png",
    //     link: 'https://project2.example.com',
    //     hasLink: false
    // },
];

const Project = ({title, description, icons, image, link, hasLink}) => (
    <div className="project-item">
        <div className='project-layout'>
            <h3 style={{marginBottom: "8px"}}>{title}</h3>
            <div className="desc-and-image">
                <p>{description}</p>
                <img src={image} alt="image"/>
            </div>
            <div className="icons-and-link">
                {icons}
                {
                    hasLink
                        ? <a href={link}><button target="_blank" ><strong>Visit →</strong></button></a>
                        : <div className={"coming-soon"}><strong>Coming Soon</strong></div>
                }
            </div>
        </div>
    </div>
);

const Projects = () => (
    <div className='projects-wrapper'>
        <SlideIn index={2}>
            <div className='project-heading'>
                <h2>Personal Projects</h2>
                <div className='divider'/>
            </div>
        </SlideIn>
        <div className="projects">
            {projectsData.map((project, index) => (
                <SlideIn index={index + 1}>
                    <div className='project-content'>
                        <Project key={index} {...project}/>
                    </div>
                </SlideIn>
            ))}
        </div>
    </div>
);

export default Projects;
