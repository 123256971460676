import './landing.css'

let Landing = () => {
    return (
        <section className='landing'>
            <div className='title-container'>
                <div className='title'>
                    Anthony Lachhman
                    <div className='sub-title'>
                        <div className='text-item'>
                            Full-Stack Engineer
                        </div>
                        <div className='text-item'>
                            Software Enthusiast
                        </div>
                        <div className='text-item'>
                            Game Developer
                        </div>
                    </div>
                    <a href="mailto: lachhman.anthony@gmail.com" className="fa fa-envelope"/>
                    <a href="https://www.linkedin.com/in/anthony-l-a9216b123/" className="fa fa-linkedin"/>
                    <a href="https://github.com/alachhman" className="fa fa-github"/>
                </div>
            </div>
            <div className='air air1'/>
            <div className='air air2'/>
            <div className='air air3'/>
            <div className='air air4'/>
        </section>
    )
}

export default Landing
