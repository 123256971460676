import { motion } from "framer-motion";
const Button = ({onClick, children}) => {
  return (
    <motion.button className="header-button"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}>
      {children}
    </motion.button>
  );
};
export default Button;
