import './body.css'
import WorkHistory from "./workHistory";
import InfoBlock from "./infoBlock";
import SlideIn from "./SlideIn";

function Body() {
	return (
		<div className="body">
			<div className='body-content'>
				<SlideIn>
					<InfoBlock/>
				</SlideIn>
				<SlideIn>
					<div className="icon-group">
						<i className="fab fa-java"/>
						<i className="fab fa-js"/>
						<i className="fab fa-node-js"/>
						<i className="fab fa-python"/>
					</div>
				</SlideIn>
				<SlideIn>
					<div className="icon-group">
						<i className="fab fa-aws"/>
						<i className="fab fa-react"/>
						<i className="fab fa-unity"/>
					</div>
				</SlideIn>
				<SlideIn>
					<div className="icon-group">
					<i className="fab fa-css3-alt"/>
					<i className="fab fa-docker"/>
					<i className="fab fa-angular"/>
					<i className="fab fa-figma"/>
					</div>
				</SlideIn>
			</div>
			<div className='body-content'>
				<SlideIn index={2}>
					<div className={"history-heading"}>
						<h2>Employment History</h2>
						<div className='divider'/>
					</div>
				</SlideIn>
				<SlideIn index={2}>
					<WorkHistory/>
				</SlideIn>
			</div>
		</div>
	);
}

export default Body;
