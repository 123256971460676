import React from "react";
import { motion } from "framer-motion";

function SlideIn({ index, repeat, children }) {
    const isMobile = window.innerWidth <= 768;

    return (
        <motion.div
            className="card"
            initial={{
                opacity: 0,
                // odd slides in from right, even from left
                x: !isMobile && (index % 2 === 0 ? 50 : -50)
            }}
            whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                    duration: 1.5
                }
            }}
            viewport={{ once: repeat ?? true }}
        >
            {children}
        </motion.div>
    );
}

export default SlideIn;