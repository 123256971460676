import React from 'react';
import pdf from '../resume.pdf';
import Button from "./button";

function Header() {
    let handleScroll = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    let handleDownload = async () => {
        // Fetch the file as a blob
        const response = await fetch(pdf);
        const blob = await response.blob();

        // Create an object URL for the blob
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Anthony-Lachhman-Resume.pdf'; // Set the file name
        a.click(); // Trigger the download

        // Cleanup: revoke the object URL after use
        window.URL.revokeObjectURL(url);
    }

    return (
        <div className={`header`}>
            <div className="header-content">
                <div className="icon-title-container">
                    <img src="/portrait.jpg" alt="App Icon" className="icon"/>
                </div>
                <div className="header-buttons">
                    <Button className="header-button" onClick={handleDownload}>RESUME</Button>
                    <Button className="header-button" onClick={handleScroll}>CONTACT</Button>
                </div>
            </div>
        </div>
    );
}

export default Header;
