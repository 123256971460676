import {useEffect, useState} from "react";

function Footer() {
    let [arrowDir, setArrowDir] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            setArrowDir(window.innerHeight + window.scrollY >= document.body.offsetHeight)
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [arrowDir]);

    let handleScroll = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    return (
        <div className="footer">
            <div className="footer-arrow">
                {
                    arrowDir
                        ? <div onClick={handleScroll} className='return-text'>
                            <i className="arrow up"/>Return To Top<i className="arrow up"/>
                        </div>
                        : <i className="arrow down"/>
                }
            </div>
        </div>
    );
}

export default Footer
