import './App.css';
import Header from "./Components/header";
import Body from "./Components/body";
import Footer from "./Components/footer";
import Landing from "./Components/landing";
import Projects from "./Components/projects";
import ContactForm from "./Components/contactForm";

function App() {
    return (
        <div className="app-container">
            <Header/>
            <Landing/>
            <Body/>
            <Projects/>
            <ContactForm/>
            <Footer/>
        </div>
    );
}

export default App;
