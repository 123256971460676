import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './contactForm.css'
import SlideIn from "./SlideIn";

const ContactForm = () => {
    const form = useRef();
    const [isSubmit, setIsSubmit] = useState(false);
    const [errors, setErrors] = useState({}); // Step 1: State for form errors

    // Step 2: Validation function
    const validateForm = () => {
        let formErrors = {};
        let formIsValid = true;

        const formData = new FormData(form.current);

        if (!formData.get("from_name")) {
            formIsValid = false;
            formErrors["from_name"] = "Name is required!";
        }

        if (!formData.get("from_email")) {
            formIsValid = false;
            formErrors["from_email"] = "Email is required!";
        } else if (!/\S+@\S+\.\S+/.test(formData.get("from_email"))) {
            formIsValid = false;
            formErrors["from_email"] = "Email is not valid!";
        }

        if (!formData.get("message")) {
            formIsValid = false;
            formErrors["message"] = "Message is required!";
        }

        setErrors(formErrors);
        return formIsValid;
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (validateForm()) { // Step 4: Validate before sending
            emailjs.sendForm('service_ydf7m4k', 'template_t16j7t9', form.current, '7PHKCLcz8DuxN482I')
                .then((result) => {
                    setIsSubmit(true)
                }, (error) => {
                    // show the user an error
                });
        }
    };

    return (
        <SlideIn index={1}>
            <div className="contact-form">
                <h2>Contact Me</h2>
                <div className='divider' />
{
                    isSubmit
                        ? <div className='thanks-message'><strong>Thank you for the comment or outreach! I'll try to get back to you when I can.</strong></div>
                        : <form ref={form} onSubmit={sendEmail} className='form'>
                            <label>Name</label>
                            {errors["from_name"] && <span className="error">{errors["from_name"]}</span>} {/* Step 3: Display errors */}
                            <input type="text" name="from_name" />

                            <label>Email</label>
                            {errors["from_email"] && <span className="error">{errors["from_email"]}</span>}
                            <input type="email" name="from_email" />

                            <label>Message</label>
                            {errors["message"] && <span className="error">{errors["message"]}</span>}
                            <textarea name="message" />

                            <input type="submit" value="Send →" className='submit-button' />
                        </form>
                }
            </div>
        </SlideIn>
    );
};

export default ContactForm;
